@import "./variables.scss";

html {
    scroll-behavior: smooth;
}

body {
    margin: 0 !important;
    overflow-x: hidden !important;
    background-color: #262626;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



h1 {
    font-size: $fsGiga;
    font-family: $proximaBold;
    letter-spacing: 1px;
    font-weight: $fw900;
    margin: 0px !important;
}
h2 {
    font-size: $fsMega;
    font-weight: $fw500;
    margin: 0px !important;
}
h3 {
    font-size: $fsXXL;
    font-weight: $fw900;
    letter-spacing: 0.9px;
}

h4 {
    font-size: $fsBig;
    font-weight: $fw400;
}

h5 {
    font-family: $proxima;
    font-size: $fsLarge;
    font-weight: $fw400;
    color: $lightGrey
}

h6 {
    font-size: $fsMid;
}

p {
    font-size: $fsSmall;
    line-height: 2.5em;
}

h1,
h2,
h3 {
    color: $white;
    font-family: $proxima;

}

h4,
h6,
p {
    font-family: $montserrat;
    color: $notWhite;
}

p6 {
    font-family: proxima-6;
}

p8 {
    font-family: proxima-8;
}

m4 {
    font-family: Montserrat;
    font-weight: 400;
}

m6 {
    font-family: Montserrat;
    font-weight: 600;
}

m1 {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 600;
}



@media only screen and (max-width: 992px) {
    mbr {
        display: block !important;
    }
}

#root {
    width: 100vw !important;
    max-width: 100vw !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* UNSELECT */

.unselect {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

/* --------------------------------------------------------------------------------------------------- */

// DISPLAY
.d-none {
    display: none;
}

// HEIGHTS
.h-100 {
    height: 100%;
}

// CURSOR POINTER
.c-pointer {
    cursor: pointer;
}

// POSITIONS
.relative {
    position: relative;
}

// BG COLORS

.bg-notbk {
    background-color: $notBlack;
}

// FONT COLORS
.cc-gold {
    color: $goldYellow;
}

// OVERFLOWS
.o-hidden {
    overflow: hidden;
}


