@import "../../styles/variables.scss";

.main-container-witnesses {
    height: auto;
    padding: $gigaMargin;
}

.mobile-visible {
    display: none;
}

@media only screen and (max-width: 992px) {
    .main-container-witnesses {
        padding: $largeMargin $smallMargin;
    }
}

@media only screen and (max-width: 767px) {
    .main-container-witnesses {
        width: 100%;
        margin-bottom: $microMargin;
    }
    .mobile-visible {
        display: block !important;
    }
}
