@import "./claim.utils.scss";

.claim-container {
    height: $claimContainerHeight;
}

.logo {
    width: $logoHeight;
}

.right-claim {
    text-align: center;
}


.quote {
    color: $notWhite;
    font-family: $montserrat;
    font-size: $fsXXL;
    padding: $veryLargeMargin;
    line-height: 1.5em;
    letter-spacing: 1px;
}

.author {
    color: $notWhite;
    font-family: $georgia;
    font-style: italic;
    font-size: $fsXL;
}

@media only screen and (max-width: 992px) {
    .claim-container {
    height: $claimContainerHeightSM;
    flex-direction: column !important;
        padding-top: $largeMargin;
        justify-content: center;
    }
    .left-claim, .right-claim {
        width: 100% !important;
        height: auto !important;
        padding: $largeMargin $tinyMargin $smallMargin $tinyMargin !important
    }


    .right-claim>h1 {
        font-size: $fsBig;
    }
    .logo {
        width: $logoHeightSM;
    }
    .quote {
        font-size: $fsMid;
        padding: $smallMargin;
    }
    .author {
        font-size: $fsSmall;
    }
}

@media only screen and (max-width: 767px) {
   
}