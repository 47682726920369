 .p-inputtext {
     outline: 0px transparent !important;
     box-shadow: 0 0 0 0.2em transparent !important;
     border-color: transparent !important;
     border-radius: 12.5px !important;
     width: 100%;
     padding-left: 13px !important;
     padding-top: 20px !important;
     padding-bottom: 8px !important;
 }

 .p-inputtext:hover, .p-inputtext:focus, .p-inputtext:active {
     outline: 0px transparent !important;
     box-shadow: 0 0 13px -4px rgba(0, 0, 0, 0.418) !important;
 }

 .p-float-label>label {
     padding-left: 10px !important;
     color: black;
     font-size: 1.1rem;
     user-select: none !important;
     -moz-user-select: none !important;
     -khtml-user-select: none !important;
     -webkit-user-select: none !important;
     -o-user-select: none !important;
 }

 .p-float-label>input:focus~label, .p-float-label>input.p-filled~label, .p-float-label>.p-inputwrapper-focus~label, .p-float-label>.p-inputwrapper-filled~label {
     top: -.85em !important;
     font-size: 12px !important;
     margin-top: 14px !important;
     color: rgba(0, 0, 0, 0.4) !important;
 }

 .p-float-label>input:-webkit-autofill~label {
     top: -.85em !important;
     font-size: 12px !important;
     margin-top: 14px !important;
     color: rgba(0, 0, 0, 0.4) !important;
 }

 .label-up {
     top: -.85em !important;
     font-size: 12px !important;
     margin-top: 14px !important;
     color: rgba(0, 0, 0, 0.4) !important;
 }


 /* CAROUSEL */
body .p-carousel-dot-icon {
    border-radius: 50px !important;
}

body .p-carousel .p-carousel-dots-container .p-carousel-dot-item.p-highlight  .p-link .p-carousel-dot-icon{
    background-color: #f2c230 !important;
}

body .p-link:focus{
    box-shadow: none !important;
}

body .p-button:enabled:focus{
    box-shadow: none !important;
}

body .p-carousel .p-carousel-content .p-carousel-prev:not(.p-disabled):hover{
    color: #f2c230 !important;
}

body .p-carousel .p-carousel-content .p-carousel-next:not(.p-disabled):hover{
    color: #f2c230 !important;
}