@import '../../styles/variables.scss';


.mobile-visible {
    display: none
}
.desktop {
    display: block
}

@media only screen and (max-width: 992px) {
    .mobile-visible {
        display: block !important;
        margin-bottom: $veryLargeMargin;
    }
    .desktop {
    display: none
    }
}