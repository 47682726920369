@import '../../styles/variables.scss';

.main-container-reference {
    padding: 25px 8%;
    height: auto;
    min-height: 500px;
    text-align: center;
}

.main-container-reference>p {
    text-align: center;
}

.special-testimonial {
    color: $lightGrey ;
}

.author {
    font-family: $montserrat !important;
    color: $white ;
    font-weight: $fw400 !important;
    font-size: $fsSmall !important ;
    font-style: normal !important ;
}

.reference-image {
    width: 15em;
}

.paragrafo {
    line-height: 1.95em;
    font-style: normal !important ;
}

@media only screen and (max-width: 767px) {
    .main-container-reference {
        padding: 25px 8%;
        height: unset !important;
        /* display: none !important; */
    }
    .paragrafo {
        font-size: 0.9rem !important;
        line-height: 1.2em;
        /* letter-spacing: 1.4px; */
        font-weight: 300;
        text-justify: newspaper !important;
    }
    .reference-image {
        width: 44%;
        margin-bottom: 20px !important;
    }

    .special-testimonial {
        font-size: $fsBig !important ;
        padding: 0 $largeMargin !important ;
    }
}