@import "../../styles/variables.scss";

.main-container-footer {
    padding-bottom: $largeMargin;
    background-color: $notBlack;
}

.main-container-footer > h5 > br {
    display: none;
}

.logo-footer {
    width: 280px;
}

.icons-container {
    width: 30%;
    margin: 0.9em 0 !important;
}

.social-icon {
    width: 2.8em;
    cursor: pointer;
    transition: transform 0.6s;
}

.social-icon:hover {
    transform: scale(1.15);
}

.social-icon:active {
    transition: transform 0.15s;
    transform: scale(0.88);
}
.privacy-wrapper {
    min-height: 55px !important;
    background-color: $black;
    padding: $tinyMargin $ultraWideMargin;
}
h6 {
    margin: 0 !important;
    font-size: $fsSmall !important;
}

h6:hover {
    transform: scale(1.07);
    transition: transform ease-out 0.1s;
    color: $goldYellow;
}

a,
a:hover,
a:active {
    text-decoration: none;
}
@media only screen and (max-width: 767px) {
    .icons-container {
        width: 70%;
    }
    .logo-footer {
        margin-top: $mediumMargin !important;
    }

    .privacy-wrapper {
        padding: $tinyMargin $mediumMargin !important;
    }

    label {
        font-weight: $fw400 !important;
        font-size: $fsXXS !important;
        color: $darkGrey !important;
    }

    h6 {
        font-size: $fsXXS !important;
    }

    // .privacy-wrapper{
    //     width: 100vw;
    //     margin-top: 5vh;
    //     flex-direction: column;
    //     align-items: center;
    // }
}
