// MARGINS
$microMargin: 2px;
$tinyMargin: 4px;
$smallMargin: 8px;
$mediumMargin: 15px;
$largeMargin: 25px;
$wideMargin: 30px;
$veryLargeMargin: 50px;
$gigaMargin: 75px;
$ultraLargeMargin: 100px;
$ultraWideMargin: 150px;
$maxMargin: 200px;

// BORDER RADIUS
$tinyRadius: 5px;
$smallRadius: 12px;
$mediumRadius: 20px;
$largeRadius: 40px;
$infiniteRadius: 100000px;

// FONT FAMILIES
$montserrat: 'Montserrat';
$proximaLight: 'proxima-3';
$proxima: 'proxima';
$proximaMid: 'proxima-6';
$proximaBold: 'proxima-8';
$georgia: 'Georgia';

// FONT SIZES
$fsMini: 10px;
$fsXXS: 12px;
$fsSmall: 14px;
$fsMid: 16px;
$fsLarge: 19px;
$fsBig: 22px;
$fsXL: 24px;
$fsXXL: 28px;
$fsMega: 34px;
$fsGiga: 45px;
$fsUltra: 65px;
$fsMAX: 85px;

// FONT WEIGHTS
$fw900: 900;
$fw600: 600;
$fw500: 500;
$fw400: 400;

// COLORS
$white: #ffffff;
$notWhite: #eaeaea;
$black: #000000;
$black50: #0000009b;
$lightBlack: #2e2e2e;
$notBlack: #262626;
$yellow: #f2c230;
$yellow70: #f2c130d3;
$yellow50: #f2c130ae;
$goldYellow: #eeb725;
$goldYellow70: #eeb825d6;
$darkYellow: #deab1e;
$darkGrey: #434343;
$subGrey: #636363;
$midGrey: #707070;
$lightGrey: #838383;
$brightGrey: #c3c3c3;


