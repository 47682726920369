@import "../../styles/variables.scss";

.desktop {
    padding: 0 $ultraLargeMargin;
}

.mobile-visible {
    display: none;
}

.guide-container > h1 > b {
    color: $goldYellow;
    font-weight: $fw600 !important;
}

@media only screen and (max-width: 992px) {
    .guide-container {
        padding: $largeMargin $smallMargin;
        margin-top: $largeMargin;
    }

    .desktop {
        display: none !important;
    }

    .p-carousel {
        padding: 0 $smallMargin;
    }

    .p-carousel-prev,
    .p-carousel-next {
        display: none !important;
    }

    .mobile-visible {
        display: block !important;
    }
}

@media only screen and (max-width: 767px) {
    .main-container-guide {
        /* width: 100vw !important; */
        /* height: auto !important; */
        display: none !important;
    }

    .guide-container > h1 {
        font-family: $proximaMid !important;
        font-weight: $fw400 !important;
        font-size: $fsXL !important;
        letter-spacing: 0.3 !important;
    }
}
