@import "../../../styles/variables.scss";

.main-container-image {
    height: 730px;
    transition: padding 0.3s ease-out;
    padding: $veryLargeMargin $ultraLargeMargin !important;
}

.image-logo {
    padding: $wideMargin;
    font-family: $montserrat;
    font-size: $fsXXL;
    font-weight: $fw600;
    color: $darkGrey;
    opacity: 0.2;
}

.image-box {
    padding: $gigaMargin $mediumMargin;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

.image-title {
    font-family: $proximaMid;
    font-size: $fsUltra;
    font-weight: $fw400;
    padding: $veryLargeMargin 0 !important;
    margin: 0px !important;
    transition: 0.5s ease-in;
}

.image-description {
    padding: 0 $maxMargin;
    font-size: $fsMid;
    line-height: 2.2em;
    transition: opacity 1.2s ease-out, height 0.8s ease-in;
    overflow: hidden;
    padding-bottom: $veryLargeMargin;
}

.image-description-frozen {
    font-size: 1.6em !important;
    /* height: 10vh; */
}

.mobile {
    display: none;
}

.desktop {
    display: block;
}
@media only screen and (max-width: 1400px) {
    .main-container-image {
        padding: $veryLargeMargin $largeMargin !important;
    }

    .image-description {
        padding: 0 $veryLargeMargin;
        font-size: $fsSmall;
    }
}

@media only screen and (max-width: 992px) {
    .main-container-image {
        width: 100vw;
        height: 100vh;
        padding: 2vh 3vw;
        margin-top: 10vh;
    }
    .image-description {
        color: #ffffff;
        font-family: "Montserrat";
        font-size: 1em !important;
        font-weight: 200;
        /* padding: 0 0; */
        line-height: 1.8em;
        transition: opacity 1.2s ease-out;
        overflow: hidden;
    }
    .mobile {
        display: block;
    }
    .desktop {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .main-container-image {
        height: 560px;
        padding: $smallMargin 0 !important;
    }

    .image-box {
        padding: $gigaMargin $tinyMargin;
        padding-bottom: 0 !important;
    }

    .image-title {
        font-size: $fsXXL !important;
        font-family: $proximaMid !important;
        margin: 0px !important;
    }
    .image-description {
        padding: 0 $smallMargin !important;
        font-size: $fsMini !important;
        font-weight: $fw500 !important;
        line-height: 1.7em;
        transition: opacity 1.2s ease-out;
        overflow: hidden;
    }
}
