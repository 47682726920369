@import "../../styles/variables.scss";

/* MAIN CONTAINER */

.main-container-start {
    width: 100%;
    height: 100vh;
    background-image: url('/assets/images/start.jpg');
}



#bk-v {
    position: absolute;
    z-index: -1;
    left: 0;
    top: -10%;
    min-width: 100%;
    min-height: 100%;
    max-height: inherit !important;
}

/* STRATO SUPERIORE */

.overlay {
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.349);
}

.subtitles > b {
    font-family: $proximaMid !important;
}

.start-logo {
    height: 490px;
}

.sign {
    width: min-content;
    height: 40px;
    margin-top: -50px;
}


.right{
    margin-right: $veryLargeMargin;
}

@media only screen and (max-width: 992px) {
.main-container-start {
height: 820px !important;
}
    
    .overlay {
        display: flex;
        flex-direction: column;
    }
    .start-logo {
        height: 450px;
        margin-bottom: -20px;
    }

.left,
.right,
.sign-wrapper {
    width: 100% !important;
}

.left {
    height: auto !important;
    padding-top: $largeMargin;
    justify-content: flex-start !important;
}

.right {
    margin-right: 0px !important;
    padding: $largeMargin !important;
}

.sign-wrapper {
align-items: flex-end;
padding-right: 25px;
}

.sign {
    width: 7em;
    height: 1.6em;
}

}

@media only screen and (max-width: 767px) {
    #bk-v {
        /* left: -13%; */
        top: 0% !important;
        min-height: 100vh;
        // }
        // .left, .right {
        //     height: 50% !important;
        // }
    }
    .start-logo {
        height: 400px;
    }
    .title {
        font-size: $fsXXL;
        letter-spacing: 0.2px;
        margin-bottom: $mediumMargin;
    }
    .subtitles {
        font-size: $fsBig;
        letter-spacing: 0.1px;
    }
    .sign-container {
        width: 65%;
        margin-top: -4px;
        margin-bottom: -25px;
    }

    .overlay {
        height: 830px !important;
        justify-content: flex-start !important;
    }
   
    .main-container-start {
        position: relative;
        width: 100vw;
        height: 100vh;
        min-height: 731px !important;
        overflow: hidden;
    }
}
