@import '../../../styles/variables.scss';

.main-tile {
    padding: $largeMargin $maxMargin;
}

.witnesses-tile-container {
    height: 700px;
    position: relative;
    padding: $largeMargin;
    border: solid 8px $yellow;
    z-index: 100;
    border-radius: $largeRadius;
    box-shadow: 0px 0px 8px 2px $yellow50;
}

.witnesses-wrapper {
    align-items: baseline;
}

.witnesses-tile-image {
    width: 250px
}

.witnesses-tile-title {
    font-size: $fsMega;
}

.witnesses-tile-description {
    padding: 0 $maxMargin ;
    text-align: center;
}


h2 {
    letter-spacing: 1.5px;
}

@media only screen and (min-width: 1500px) and (max-width: 1890px) {
.witnesses-tile-description {
        padding: 0 $veryLargeMargin !important;
    }

}

@media only screen and (max-width: 1499px) {
    
    .main-tile {
        padding: $largeMargin $veryLargeMargin;
    }
    
    .witnesses-tile-image {
        width: 200px
    }
    
    .witnesses-tile-description {
        padding: 0 $ultraLargeMargin !important;
    }
}
@media only screen and (max-width: 1280px) {
    
    .witnesses-tile-description {
        padding: 0 $largeMargin !important;
        font-size: $fsXXS ;
    }
    
}

@media only screen and (max-width: 767px) {
    .main-tile {
        padding: 0 !important;
    }
    .witnesses-tile-container {
        height: 550px !important;
    }

    .social-icon {
        width: 30px;
    }
    
    .social-icon-wrapper {
        margin-top: $microMargin;
        width: 100% !important;
        padding: $largeMargin $veryLargeMargin !important;
    }
    .witnesses-wrapper {
        align-items: flex-start;
    }
    
    .witnesses-tile-image {
        margin-top: $smallMargin;
        width: 10em
    }
    .witnesses-tile-title {
        font-size: $fsBig;
        text-align: center;
        padding-top: $smallMargin;
    }
    .witnesses-tile-description {
        font-size: $fsMini;
        padding: $smallMargin !important;
        margin-bottom: $mediumMargin !important;
        line-height: 1.8em;
        text-align: center;
    }
    .witnesses-tile-container {
padding: $tinyMargin !important;
        box-shadow: 0px 0px 5px 1px $yellow50;
    }
}