@import "../styles/variables.scss";

.main-container-linktree {
    height: 100vh !important;
    background-image: url("../assets/images/pattern-white.png");
    background-color: $black50;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
}

.linktree-container {
    height: 700px;
    padding: $largeMargin $veryLargeMargin;
    background-color: $black;
    border: solid 10px $yellow;
    border-radius: $largeRadius;
}

.linktree-logo {
    width: 230px;
    margin-bottom: $mediumMargin;
}
a {
    padding: 10px 0 !important;
}
.linktree-button {
    border-radius: $infiniteRadius;
    border: solid 1px $yellow;
    background-color: $black;
    padding: $microMargin $largeMargin;
    transition: transform 0.14s ease-out, border 0.1s ease-out;
}
.linktree-button:hover {
    background-color: $lightBlack;
    border: solid 2px $goldYellow;
    transform: scale(1.06);
}
.linktree-button:active {
    background-color: $notBlack;
    border: solid 2.5px $darkYellow;
    transform: scale(0.97);
}

.linktree-icon {
    width: 2.5em;
}
.username {
    margin: 0px !important;
}

@media only screen and (max-width: 1285px) {
    .linktree-button {
        width: 40% !important;
    }
}
@media only screen and (max-width: 1024px) {
    .linktree-button {
        width: 45% !important;
    }
}

@media only screen and (max-width: 912px) {
    .linktree-container {
        width: 85% !important;
        padding: $mediumMargin;
    }
    .linktree-button {
        width: 60% !important;
    }
    .linktree-icon {
        font-size: 1.3em !important;
    }
    a {
        padding: $tinyMargin 0 !important;
    }
}

@media only screen and (max-width: 570px) {
    .linktree-button {
        padding: 10px 30px;
        width: 70% !important;
    }
    .linktree-container {
        height: 600px;
        padding: $mediumMargin !important;
    }
    .username {
        font-size: $fsXXS;
    }
    .linktree-logo {
        width: 150px;
        margin-bottom: $smallMargin;
    }
    .linktree-icon {
        font-size: 0.7em !important;
    }

    a {
        padding: 0 !important;
    }
}

@media only screen and (max-width: 435px) {
    .linktree-container {
        width: 90% !important;
    }
     .linktree-button {
        padding: 8px $largeMargin;
        width: 80% !important;
    }
}
@media only screen and (max-width: 355px) {
    .linktree-container {
        width: 95% !important;
    }
    .linktree-button {
        width: 90% !important;
        padding: 10px $mediumMargin;
    }
}
