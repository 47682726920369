@import '../styles/variables.scss';

.main-container-thankyou {
    height: 100vh !important;
    background-image: url('../assets/images/pattern-white.png');
    background-color: $black50;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
}

.thankyou-container {
    height: 650px;
    padding: $veryLargeMargin $mediumMargin;
    background-color: $black;
    border: solid 10px $yellow;
    border-radius: $largeRadius;
}

.thankyou-logo {
    width: 300px;
}

.thankyou-container>h1 {
    font-family: $proxima;
    font-weight: $fw600;
    font-size: $fsMega;
}

.thankyou-container>h2 {
    font-family: $montserrat;
    font-size: $fsXL;
}

.main-container-thankyou br {
    display: none
}

@media only screen and (max-width: 992px) {
    .thankyou-container>h1 {
        font-size: $fsXXL;
    }
    
    .thankyou-container>h2 {
        font-size: $fsBig;
    }
    


}

@media only screen and (max-width: 810px) {
    .thankyou-container {
    border: solid 10px #f2c230;
    }
    .thankyou-container>h1 {
        font-size: $fsLarge;
    }
    
    .thankyou-container>h2 {
        font-size: $fsMid;
    }
    
    .thankyou-logo {
        width: 250px;
    }
}

@media only screen and (max-width: 590px) {

    .thankyou-container>h1 {
        font-size: $fsLarge;
        line-height: 1.5em;
        text-align: center;
    }
    
    .thankyou-container>h2 {
        font-size: $fsSmall;
        line-height: 1.5em;
        text-align: center;
    }
    br {
        display: block
    }
}