@import "../../../styles/variables.scss";

.button {
    padding: $mediumMargin;
    border-radius: $largeRadius;
    min-width: 40%;
    outline: none !important;
    background-color: $yellow !important;
    cursor: pointer !important;
    box-shadow: 0px 0px 9px -5px rgba(0, 0, 0, 0.712);
    transition: background-color 0.15s, padding 1s !important;
}

.button:hover {
    background-color: $goldYellow !important;
}

.button:active {
    transition: background-color 0.05s !important;
    background-color: $darkYellow !important;
    box-shadow: 0px 0px 6px -5px rgba(0, 0, 0, 0.712);
}

.button > h4 {
    width: 100% !important;
    margin: 0px !important;
    color: $darkGrey;
    font-family: $proximaMid;
    font-size: $fsMid;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .button {
        font-size: $fsSmall;
        padding: $tinyMargin $smallMargin;
    }

    .button > h4 {
        padding: $smallMargin $veryLargeMargin !important
    }
}
