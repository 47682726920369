.switch-language-container {
    min-width: 70px;
    max-width: 90px;
    min-height: 70px;
    color: white;
    position: fixed;
    right: 1.6vw;
    top: 4vh;
    border-radius: 100%;
    /* background-color: #ffffff; */
    text-align: center;
    z-index: 1000;
    cursor: pointer;
}

.language-icon {
    width: 3vw;
    height: 3vw;
}

@media only screen and (max-width: 992px) {
    .language-icon {
        width: 7vw;
        height: 7vw;
    }
}

@media only screen and (max-width: 767px) {
    .language-icon {
        width: 7vw;
        height: 7vw;
        position: fixed;
        right: 2vw;
        top: 4vh;
    }
}