@import "../../styles/variables.scss";

/* MAIN CONTAINER */

.main-container-about {
    justify-content: space-around;
    height: 650px;
}

.right-about {
    padding: $mediumMargin $veryLargeMargin;
}

.right-about,
.left-about {
    max-width: 100% !important;
}

.left-about {
    padding: $gigaMargin;
}

.about-image,
.about-image-reverse {
    width: 600px;
    max-width: 38vw;
    border-radius: 15px;
}

.about-start p {
    font-family: "Montserrat";
    margin-top: $veryLargeMargin;
    font-size: $fsMid;
    color: $notWhite;
}

.reverse {
    flex-direction: row-reverse !important;
}

/* TABLET & Smaller */

@media only screen and (max-width: 992px) {
    .main-container-about {
        align-items: center;
        justify-content: center;
        flex-direction: column !important;
        margin-top: $veryLargeMargin !important;
    }
    

        .about-start>p {
        font-size: $fsXXS;
    }

    .about-image,
    .about-image-reverse {
        width: 270px !important;
        max-width: 270px !important;
        margin-left: 0;
    }

    .right-about,
    .left-about {
        width: 100% !important;
        max-width: 100% !important;
        padding: $mediumMargin $gigaMargin!important;
    }
}
/* TABLET & Smaller */

@media only screen and (max-width: 560px) {
    .right-about,
    .left-about {
        width: 100% !important;
        max-width: 100% !important;
        padding: $mediumMargin !important;
    }

}
