@import "../../styles/variables.scss";

.main-container-form {
    position: relative;
    margin-top: $gigaMargin;
    padding: $ultraLargeMargin 0;
    height: 580px;
    background-image: url("./form-bg.jpg");
    background-position: bottom ;
    background-repeat: no-repeat;
    background-size: cover;
    
}

h3 {
    font-family: $proxima !important;
    letter-spacing: 0 !important;
    font-weight: $fw600 !important;
    color: $white !important;
}

h3 b {
    color: $goldYellow;
}

p {
    color: white;
    font-size: 1.1rem;
    opacity: 1;
    transition: opacity 0.15s;
}

.submit-contianer {
    background-color: $goldYellow;
    border-radius: $infiniteRadius !important;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.452);
    transition: background-color 0.15s, box-shadow 0.15s, transform 0.45s;
    cursor: pointer;
}
.submit-contianer > p {
    color: $darkGrey;
}
.submit-contianer:hover {
    background-color: $goldYellow70;
    font-weight: $fw600 !important;
    box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.452) inset;
}

.submit-contianer:active {
    background-color: $darkYellow;
    font-weight: $fw600 !important;
    transition: background-color 0.05s, box-shadow 0.05s, transform 0.05s;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.452) inset;
    transform: scale(0.96);
}

.submit-contianer > p {
    padding: 0px !important;
    margin: 0px !important;
}

.disabled {
    transition-timing-function: ease-out;
    background-color: transparent !important;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.452) !important;
}
.disabled > p {
    color: $white;
}

.disabled:hover {
    font-weight: $fw600 !important;
    background-color: rgba(255, 0, 0, 0.352) !important;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.452) !important;
}

.disabled:active {
    font-weight: $fw600 !important;
    background-color: rgba(143, 143, 143, 0.352) !important;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.452) !important;
}

.disabled:active > p {
    opacity: 0.5;
}

/* .disabled>p {
    color: rgba(255, 255, 255, 0.5) !important;
} */

.submit-complete {
    overflow: hidden !important;
    height: 0vh;
    margin-bottom: 0vh;
    transition: height 0.7s, margin-bottom 0.7s;
}

.main-container-form br {
    display: none
}

@media only screen and (max-width: 990px) {
.main-container-form br {
    display: block
}
}

@media only screen and (max-width: 767px) {
    .main-container-form {
        height: 550px;
    }
    .form-container {
        width: 100% !important;
        height: 100% !important;
        flex-direction: column;
    }
    .submit-contianer {
        margin-top: 25px !important;
        width: 75% !important;
    }

    .form-container > h3 {
        text-align: center;
        font-size: $fsXL !important;
    }
}
