@import '../../../styles/variables.scss';

.guide-tile-container {
    width: 33.3%;
    padding: 0 5px;
    margin-bottom: 50px !important;
}

.guide-tile-icon {
    width: 5em;
}

.guide-tile-title {
    font-family: proxima;
    font-size: 1.8em;
    color: #707070;
}

.guide-tile-description {
    font-family: 'Montserrat';
    padding-right: 4vw;
    line-height: 1.6em;
}

@media only screen and (max-width: 992px) {
    .guide-tile-container {
        width: 100%;
        padding: 0px !important;
        margin-bottom: 30px !important;
    }
    .guide-tile-icon {
        margin-bottom: 0.4em;
    }
}

@media only screen and (max-width: 767px) {
    .guide-tile-container {
        width: 100%;
        padding: 0px !important;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
    }
    .guide-tile-icon {
        width: 100px;
        margin-bottom: $smallMargin;
    }

    .guide-tile-container>h4 {
        font-size: $fsMid;
        text-align: center;
        padding: $smallMargin;
    }
}